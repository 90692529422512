<template>
    <div v-if="showMenu" @click="toggleMenu" class="absolute top-0 left-0 right-0 bottom-0" style="z-index: 10; background: transparent"></div>
    <div class="candidate relative w-[100%]">
        <CandidateMenu :showMenu="showMenu" :toggleMenu="toggleMenu" :candidateStatus="candidate.status" :candidateId="candidate._id" style="top: 15%; right: 10%" :toggleLoading="toggleLoading" />
        <div class="header">
            <!-- <span class="dots"
        ><img loading="lazy"  decoding="async" src="../assets/Images/icons/dots-2.svg" alt="dots"
      /></span> -->
            <div class="profile">
                <div class="profile-avatar">
                    {{ candidateInfo?.Last_name.charAt(0).toUpperCase() + "." + candidateInfo?.First_name.charAt(0).toUpperCase() }}
                </div>
                <span class="profile-name">{{ candidateInfo?.Last_name + " " + candidateInfo?.First_name }}</span>
                <div>
                    <!-- <button class="nav-btn btn-scale" style="background-color: transparent" @click="toggleMenu" :class="{ hideText: !SidebarViewOpen }"> -->
                    <font-awesome-icon :icon="['fas', 'ellipsis']" class="nav-icon" @click="toggleMenu" style="color: #fff; cursor: pointer" />
                    <!-- </button> -->
                </div>
                <!-- <img loading="lazy"  decoding="async" class="profile-image" :src="getImgUrl(candidateInfo?.Avatar)" alt="candidate-image" /> -->
            </div>
        </div>
        <div class="info">
            <span>Info</span>
            <div class="info-section">
                <div class="grid-row-info">
                    <span class="label-span">Email</span>
                    <span class="email" @click="copyEmail">{{ candidateInfo?.Email }} <img loading="lazy" decoding="async" src="../assets/Images/icons/copy-icon.svg" alt="copy-icon" /></span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Status") }}</span>
                    <span class="status" :class="passed ? 'passed' : 'failed'">{{ $t("Passed on") }}: Jun 13,2022</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Position") }}</span>
                    <span class="label-span">Frontend dev</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Assessment") }}</span>
                    <span class="label-span">Sample Assessment</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CandidateMenu from "@/components/CandidateMenu.vue";

export default {
    name: "CandidateArchiveCard",
    components: {
        CandidateMenu,
    },
    props: ["passed", "backgroundHeader", "candidate", "candidateInfo", "toggleLoading"],
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        copyEmail() {
            const emailText = this.candidateInfo.Email;
            const el = document.createElement("textarea");
            el.value = emailText;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.isVisible = true;
            setTimeout(() => {
                this.isVisible = false;
            }, 5000);
        },
    },
};
</script>

<style scoped lang="scss">
.candidate {
    background: #fff;
    border-radius: 20px;
    padding: 8px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 10%);
}

.header {
    padding: 8px;
    filter: drop-shadow(0px 18px 40px rgba(112, 144, 176, 0.12));
    border-radius: 17px;
    background-color: #c3cad9;
}

.dots {
    display: block;
}

.profile {
    width: 100%;
    margin-top: -8px;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    padding-top: 2%;
    gap: 0.5rem;
    flex-direction: row;
}

.profile-avatar {
    width: 68.266px;
    height: 68.266px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1136.629px;
    background: #1485fd;
    color: #fff;
    font-family: Inter;
    font-size: 18.204px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile-name {
    width: 75%;
    text-align: left;
    font-weight: 700;
    font-size: 28px;

    color: #fff;
}
.profile-image {
    max-width: 25%;
}

.grid-row-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    text-align: left;
    margin-bottom: 16px;
}

.info > span {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: #a3aed0;
    margin: 24px 0px;
}

.info-section {
    margin-left: 24px;
}

.label-span {
    font-weight: 400;
    font-size: 13px;
    color: #343637;
}

.email {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #2196f3;
}

.email > img {
    margin-left: 8px;
}

.status {
    width: fit-content;
    padding: 2px 12px;
    font-weight: 400;
    font-size: 13px;
    color: #f7f8f9;
    border-radius: 10px;
}

.passed {
    background: #05cd99;
}

.failed {
    background: #e62e7b;
}
</style>
